import { ParsedUrlQuery } from 'querystring';

import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import { getCanonicalUrl } from '@hultafors/shared/helpers';

import { datoApi, getLocaleFromDato } from '@hultafors/hellberg/api';
import { useGlobal } from '@hultafors/hellberg/hooks';
import {
  GlobalFields,
  InformationPageFragment,
  PageProps,
} from '@hultafors/hellberg/types';

import { prodDomains } from '../next.domains';

const Page = dynamic(() =>
  import('@hultafors/hellberg/components').then((mod) => mod.Page),
);
const ContentArea = dynamic(() =>
  import('@hultafors/hellberg/components').then((mod) => mod.ContentArea),
);
const DynamicContent = dynamic(() =>
  import('@hultafors/hellberg/components').then((mod) => mod.DynamicContent),
);
const SplitHeroBlock = dynamic(() =>
  import('@hultafors/hellberg/components').then((mod) => mod.SplitHeroBlock),
);
const RelatedProductsBlock = dynamic(() =>
  import('@hultafors/hellberg/components').then(
    (mod) => mod.RelatedProductsBlock,
  ),
);
const Button = dynamic(() =>
  import('@hultafors/hellberg/components').then((mod) => mod.Button),
);

export interface GeneralContentPageProps extends PageProps {
  content: InformationPageFragment;
  slug: string;
}

interface GeneralContentPageParams extends ParsedUrlQuery {
  slug: string;
}

interface GeneralContentPagePath {
  params: GeneralContentPageParams;
  locale?: string;
}

export const getStaticPaths: GetStaticPaths<GeneralContentPageParams> = async ({
  defaultLocale,
}) => {
  const dato = datoApi(defaultLocale);
  const {
    data: { allInformationPages },
  } = await dato.getStaticPathsAllInformationPages();

  const paths: GeneralContentPagePath[] =
    allInformationPages
      .filter(({ _allReferencingInformationListPages }) => {
        return _allReferencingInformationListPages.length === 0;
      })
      .flatMap(({ slug, _locales }) => {
        return _locales.filter(Boolean).map((datoLocale) => {
          const locale = getLocaleFromDato(datoLocale);
          return {
            locale,
            params: { slug },
          };
        });
      }) ?? [];
  return {
    fallback: 'blocking',
    paths,
  };
};

export const getStaticProps: GetStaticProps<
  GeneralContentPageProps,
  GeneralContentPageParams
> = async ({ locale, locales, params: { slug } }) => {
  const revalidate = 60; // 1 minutes
  try {
    const dato = datoApi(locale);

    const {
      data: { informationPage: content, ...globalFields },
    } = await dato.getInformationPage(slug);
    if (!content || content === null) {
      return {
        notFound: true,
      };
    }

    const path = `/${slug}`;

    const canonical = getCanonicalUrl(
      locale,
      prodDomains,
      'www.hellbergsafety.com',
      path,
    );

    return {
      props: {
        canonical,
        content,
        slug,
        ...(globalFields as GlobalFields),
      },

      revalidate,
    };
  } catch (error) {
    console.error(error);
    return {
      notFound: true,
      revalidate,
    };
  }
};

const ContentPageContent: React.FC<{
  dynamicContent?: InformationPageFragment['dynamicContent'];
  contentArea?: InformationPageFragment['contentArea'];
}> = ({ dynamicContent, contentArea }) => {
  const { isEnabled } = useGlobal();

  if (isEnabled('informationPageContentArea')) {
    return <ContentArea largeMargin content={contentArea} />;
  }

  if (dynamicContent) {
    return <DynamicContent content={dynamicContent} />;
  }

  return null;
};

export const GeneralContentPage: NextPage<GeneralContentPageProps> = ({
  content,
  slug,
  canonical,
}) => {
  if (!content) {
    return null;
  }

  return (
    <Page seo={content.seo} canonical={canonical}>
      <SplitHeroBlock
        title={content.heroTitle}
        description={content.heroDescription}
        image={content?.heroImage}
        centerContent={content.centerContent}
        priority={true}
      />

      <ContentPageContent
        dynamicContent={content.dynamicContent}
        contentArea={content.contentArea}
      />

      <RelatedProductsBlock
        productRelationListId={content.products}
        header={content.relatedProducts}
      />

      {slug === 'cookie-policy' && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            // TODO fix window ts
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onClick={() => window.CookieScript.instance.show()}
            style={{ marginBottom: '40px', padding: '10px' }}
          >
            Adjust cookies
          </Button>
        </div>
      )}
    </Page>
  );
};

export default GeneralContentPage;
